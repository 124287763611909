import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO, { rootTitle } from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import { MDXConnection, SiteNode } from "../queries"
import HeroImage from "../components/heroImage"
import "./font-faces.css"

import "../shared.css"

class BlogIndex extends React.Component<BlogIndexProps, {}> {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={rootTitle} />
        <HeroImage />
        <div
          style={{
            marginTop: rhythm(1),
            marginBottom: rhythm(1),
          }}
        >
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <article key={node.fields.slug}>
                <header
                  style={{
                    marginBottom: rhythm(3 / 4),
                    marginTop: rhythm(3 / 4),
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    flexDirection: "row",
                    gap: "1.5rem",
                  }}
                >
                  <h3
                    style={{
                      ...scale(1 / 4),
                      margin: 0,
                    }}
                  >
                    <Link
                      style={{ textDecoration: `none` }}
                      to={node.fields.slug}
                    >
                      {title}
                    </Link>
                  </h3>
                  <time style={{ margin: 0, flexShrink: 0 }}>
                    {node.frontmatter.date}
                  </time>
                </header>
              </article>
            )
          })}
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

interface BlogIndexQuery {
  site: SiteNode
  allMdx: MDXConnection
}

interface BlogIndexProps {
  data: BlogIndexQuery
  location: Location
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`
