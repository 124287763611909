/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { CSSProperties } from "react"

import "./heroImage.css"

const HeroImage = () => {
  const data = useStaticQuery(graphql`
    query HeroImageQuery {
      heroImage: file(absolutePath: { regex: "/hero.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const imageStyle: CSSProperties = {
    width: "100%",
    marginBottom: "0",
  }

  return (
    <div
      className="w-screen sm:w-[initial] -mx-[50vw] sm:-mx-2 left-[50%] right-[50%] sm:left-0 sm:right-0 relative overflow-clip sm:rounded-md"
      style={{
        border: "1px solid rgba(0,0,0,0.1)",
      }}
    >
      <Image
        imgStyle={imageStyle}
        className={"hero-image-wrapper"}
        fluid={data.heroImage.childImageSharp.fluid}
      />
    </div>
  )
}

export default HeroImage
